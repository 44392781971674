<section class="content">
  <div class="form-content">
    <mat-card class='center'>
      <mat-card-header>
        <!-- <div mat-card-avatar class="header-image"></div> -->
        <mat-card-title>Icebox Connect</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form>
          <mat-form-field appearance="outline" class="full" [hideRequiredMarker]="true">
            <mat-label>
              <mat-icon>store</mat-icon> Compass Site ID
            </mat-label>
            <input matInput type="number" class="full" required #compassSiteId>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full" [hideRequiredMarker]="true">
            <mat-label>
              <mat-icon>lock_open</mat-icon> Password
            </mat-label>
            <input matInput type="string" class="full" required #password>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full" [hideRequiredMarker]="true">
            <mat-label>
              <mat-icon>home</mat-icon> Use Residential Shipping?
            </mat-label>
            <mat-select placeholder="Select" required #useResidentialShip>
              <mat-option value="true">Yes - this site is B2C</mat-option>
              <mat-option value="false">No - this site is B2B</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full" [hideRequiredMarker]="true">
            <mat-label>
              <mat-icon>vpn_key</mat-icon> Payeezy Api Key
            </mat-label>
            <input matInput type="string" class="full" required #payeezyApiKey>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full" [hideRequiredMarker]="true">
            <mat-label>
              <mat-icon>vpn_key</mat-icon> Payeezy Api Secret Key
            </mat-label>
            <input matInput type="string" class="full" required #payeezyApiSecretKey>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full" [hideRequiredMarker]="true">
            <mat-label>
              <mat-icon>monetization_on</mat-icon> Payeezy Api Token
            </mat-label>
            <input matInput type="string" class="full" required #payeezyApiToken>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full" [hideRequiredMarker]="true">
            <mat-label>
              <mat-icon>vpn_key</mat-icon> Shopify Api Key
            </mat-label>
            <input matInput type="string" class="full" #shopifyApiKey>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full" [hideRequiredMarker]="true">
            <mat-label>
              <mat-icon>vpn_key</mat-icon> Shopify Api Password
            </mat-label>
            <input matInput type="string" class="full" #shopifyApiPassword>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full" [hideRequiredMarker]="true">
            <mat-label>
              <mat-icon>vpn_key</mat-icon> Shopify Api Secret
            </mat-label>
            <input matInput type="string" class="full" #shopifyApiSharedSecret>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full" [hideRequiredMarker]="true">
            <mat-label>
              <mat-icon>vpn_key</mat-icon> Shopify Access Token
            </mat-label>
            <input matInput type="string" class="full" #shopifyAccessToken>
          </mat-form-field>

          <button class='install-button' type='button' mat-raised-button color="primary"
            (click)='onClickInstall()'>Install</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</section>
