import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InstallComponent } from './install/install.component';
import { RefundComponent } from './refund/refund.component';

const routes: Routes = [
  { path: 'install-connector-app', component: InstallComponent },
  { path: 'refund', component: RefundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
