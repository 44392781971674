import { Component, ViewChild, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss'],
})
export class InstallComponent {
  @ViewChild('compassSiteId') compassSiteId: ElementRef;
  @ViewChild('password') password: ElementRef;
  @ViewChild('useResidentialShip') useResidentialShip: any;
  @ViewChild('payeezyApiKey') payeezyApiKey: ElementRef;
  @ViewChild('payeezyApiSecretKey') payeezyApiSecretKey: ElementRef;
  @ViewChild('payeezyApiToken') payeezyApiToken: ElementRef;
  @ViewChild('shopifyApiKey') shopifyApiKey: ElementRef;
  @ViewChild('shopifyApiPassword') shopifyApiPassword: ElementRef;
  @ViewChild('shopifyApiSharedSecret') shopifyApiSharedSecret: ElementRef;
  @ViewChild('shopifyAccessToken') shopifyAccessToken: ElementRef;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  onClickInstall = (): void => {
    const compassSiteId: string = encodeURIComponent(this.compassSiteId.nativeElement.value);
    const password: string = encodeURIComponent(this.password.nativeElement.value);
    const useResidentialShip: string = encodeURIComponent(this.useResidentialShip.value);
    const payeezyApiKey: string = encodeURIComponent(this.payeezyApiKey.nativeElement.value);
    const payeezyApiSecretKey: string = encodeURIComponent(this.payeezyApiSecretKey.nativeElement.value);
    const payeezyApiToken: string = encodeURIComponent(this.payeezyApiToken.nativeElement.value);
    const shopifyApiKey: string = encodeURIComponent(this.shopifyApiKey.nativeElement.value);
    const shopifyApiPassword: string = encodeURIComponent(this.shopifyApiPassword.nativeElement.value);
    const shopifyApiSharedSecret: string = encodeURIComponent(this.shopifyApiSharedSecret.nativeElement.value);
    const shopifyAccessToken: string = encodeURIComponent(this.shopifyAccessToken.nativeElement.value);
    const URL = `https://${this.document.location.host}/shopify/install?compassSiteId=${compassSiteId}&password=${password}&useResidentialShip=${useResidentialShip}&payeezyApiKey=${payeezyApiKey}&payeezyApiSecretKey=${payeezyApiSecretKey}&payeezyApiToken=${payeezyApiToken}&shopifyApiKey=${shopifyApiKey}&shopifyApiPassword=${shopifyApiPassword}&shopifyApiSharedSecret=${shopifyApiSharedSecret}&shopifyAccessToken=${shopifyAccessToken}`;
    window.location.href = URL;
  };
}
