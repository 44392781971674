import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
})
export class RefundComponent implements OnInit {
  message: string;

  constructor(@Inject(DOCUMENT) private document: Document, private http: HttpClient) {
    this.message = 'Trying to refund order...';
  }

  getRefund(hmac: string, paramsWithoutHmac: string): any {
    const URL = `http://${this.document.location.host}/shopify/refund`;
    return this.http.get(URL, {
      responseType: 'text',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-shopify-hmac-sha256': hmac,
        params: paramsWithoutHmac,
      },
    });
  }

  ngOnInit(): void {
    const queryString = window.location.search.replace(/&amp;/g, '&');
    const urlParams = new URLSearchParams(queryString);
    const hmac = urlParams.get('hmac');
    urlParams.delete('hmac');
    const paramsWithoutHmac = urlParams.toString();
    this.getRefund(hmac, paramsWithoutHmac).subscribe(
      () => {
        this.message = 'Succeeded refunding order. Returning to previous page in 5 seconds.';
        setTimeout(() => {
          history.go(-1);
        }, 5000);
      },
      () => {
        this.message = 'There was an error trying to refund order, try again. Returning to previous page in 5 seconds.';
        setTimeout(() => {
          history.go(-1);
        }, 5000);
      },
    );
  }
}
